import {Button, Col, Form, Input, Row} from 'antd';
import React from 'react';
import {GAP_SIZE_2, GAP_SIZE_BASE} from '../../constants';
import * as styles from './form.module.scss';

export default function CommercialForm() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>请填写以下资料！</div>
      <Form>
        <Row gutter={[GAP_SIZE_2, 0]}>
          <Col span={12}>
            <Form.Item name="name">
              <Input placeholder="您的姓名" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="company">
              <Input placeholder="您的公司名称" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phone">
              <Input placeholder="您的手机号码" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="city">
              <Input placeholder="您的所在的城市" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description">
              <Input.TextArea placeholder="请简单描述您想问询的内容" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                确认提交
              </Button>
            </Form.Item>
          </Col>
          <Col span={24} className={styles.tips}>
            我们将尊重您的隐私权，并承诺会对您的个人信息和其他数据进行严格保密。
          </Col>
        </Row>
      </Form>
      <div className={styles.contact}>
        <div>咨询电话：021-54893887</div>
        <div>邮箱：business@heyfocustech.com</div>
      </div>
    </div>
  );
}
