import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';
import HfTitle from '../hf-title';
import companyImage from '../../images/section-communication-company.svg';
import emailImage from '../../images/section-communication-email.svg';
import locationImage from '../../images/section-communication-location.svg';
import phoneImage from '../../images/section-communication-phone.svg';
import qrcodeImage from '../../images/section-communication-qrcode.svg';
import labImage from '../../images/section-communication-lab.svg';
import {address, companyName, email, phoneNumber} from '../../constants';
import {QRCodeSVG} from 'qrcode.react';

type PropTypes = {};
const INFOS_DATA = [
  {
    icon: companyImage,
    texts: [
      '唯视智能信息科技（广州）有限公司',
      '020-31602173',
      '广东省广州市黄埔区香雪大道中85号1402房',
    ],
  },
  {
    icon: emailImage,
    texts: ['人事邮箱', 'hr@heyfocustech.com'],
  },
  {
    icon: qrcodeImage,
    component: (
      <StaticImage
        className={styles.qrcodeImage}
        src="../../images/qrcode.jpg"
        alt=""
      />
    ),
  },
];

export default function SectionCommunication({}: PropTypes) {
  return (
    <section className={styles.section}>
      <HfTitle
        className={styles.title}
        title="COMMUNICATION"
        subTitle="通讯信息"
      />

      <Row>
        {INFOS_DATA.map(item => (
          <Col span={24} sm={8} className={styles.info}>
            <img className={styles.icon} src={item.icon} />
            {item.texts?.map(text => (
              <div>{text}</div>
            ))}
            <div>{item.component}</div>
          </Col>
        ))}
      </Row>
    </section>
  );
}
