import React, {useState} from 'react';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {motion, AnimatePresence} from 'framer-motion';
import bg1Image from '../../images/contact-1.jpg';
import bg2Image from '../../images/contact-2.jpg';
import CommercialForm from './commercial-form';
import HireForm from './hire-form';
import Jobs from './jobs';

type PropTypes = {};
const TABS_DATA = [
  {
    name: '商务合作',
    bgImage: bg1Image,
  },
  {
    name: '应聘申请',
    bgImage: bg2Image,
  },
];

export default function SectionCooperation({}: PropTypes) {
  const [activeTab, setActiveTab] = useState(1);
  const currentTab = TABS_DATA[activeTab];
  return (
    <div className={styles.section}>
      <section className={styles.tabs}>
        {TABS_DATA.map((tab, index) => (
          <div
            className={clsx(styles.tab, activeTab === index && styles.active)}
            key={tab.name}
            onClick={() => setActiveTab(index)}
          >
            {tab.name}
          </div>
        ))}
      </section>
      <section
        className={styles.content}
        style={{backgroundImage: `url('${currentTab.bgImage}')`}}
      >
        <div className={styles.form}>
          <AnimatePresence exitBeforeEnter>
            {activeTab === 0 ? (
              <motion.div
                key="commercial"
                initial={{opacity: 0, x: -20}}
                animate={{opacity: 1, x: 0}}
                exit={{opacity: 0, x: -20}}
              >
                <CommercialForm />
              </motion.div>
            ) : (
              <motion.div
                key="hire"
                initial={{opacity: 0, x: -20}}
                animate={{opacity: 1, x: 0}}
                exit={{opacity: 0, x: -20}}
              >
                <HireForm />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </section>
      <AnimatePresence>
        {activeTab === 1 && (
          <motion.div
            className={styles.jobs}
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
          >
            <Jobs />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
