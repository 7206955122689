import React, {useState} from 'react';
import {PageProps} from 'gatsby';
import DefaultLayout from '../layouts/default';
import HfContactHero from '../components/hf-contact-hero';
import * as styles from './contact.module.scss';
import SectionCooperation from '../components/section-cooperation';
import HfSeo from '../components/hf-seo';

const ContactPage = ({location}: PageProps) => {
  return (
    <DefaultLayout location={location} className={styles.container}>
      <HfContactHero />
      <SectionCooperation />
    </DefaultLayout>
  );
};

export default ContactPage;

export const Head = () => <HfSeo title="加入我们 | 唯视智能" />;
