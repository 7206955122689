import dayjs from 'dayjs';
import {graphql, StaticQuery, useStaticQuery} from 'gatsby';
import React from 'react';
import HfAccordion, {ViewModel as AccordionViewModel} from '../hf-accordion';
import * as styles from './jobs.module.scss';

export default function Jobs() {
  const data: DirectusGraphqlResponse<{job: Job[]}> = useStaticQuery(graphql`
    query {
      directus {
        job(sort: "weight") {
          id
          name
          requirements
          salary
          duties
          location
          date_created
        }
      }
    }
  `);
  const ACCORDION_DATA: AccordionViewModel = {
    columns: [
      {
        title: '招聘职位',
        dataIndex: 'name',
        key: 'position',
      },
      {
        title: '薪资范围',
        dataIndex: 'salary',
        key: 'salary',
      },
      {
        title: '工作地点',
        dataIndex: 'location',
        key: 'location',
      },
      {
        title: '发布日期',
        dataIndex: 'publishDate',
        key: 'publishDate',
      },
    ],
    dataSource: data.directus.job?.map(job => ({
      ...job,
      publishDate: dayjs(job.date_created).format('YYYY-MM-DD'),
    })),
    expand: ({duties, requirements}) => (
      <div className={styles.jobDescription}>
        <div className={styles.duty}>
          <div className={styles.title}>岗位职责：</div>
          <div dangerouslySetInnerHTML={{__html: duties}} />
        </div>
        <div className={styles.divider} />
        <div className={styles.requirement}>
          <div className={styles.title}>任职要求：</div>
          <div dangerouslySetInnerHTML={{__html: requirements}} />
        </div>
      </div>
    ),
  };
  return (
    <div className={styles.jobs}>
      <HfAccordion vm={ACCORDION_DATA} />
    </div>
  );
}
