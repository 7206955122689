import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';
import SectionCommunication from '../section-communication';

type PropTypes = {};

export default function HfContactHero({}: PropTypes) {
  return (
    <div className={styles.hero}>
      <StaticImage
        className={styles.bg}
        src="../../images/contact-hero.jpg"
        alt=""
      />
      <div className={styles.communication}>
        <SectionCommunication />
      </div>
    </div>
  );
}
