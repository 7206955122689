import React from 'react';
import {Button, Col, Form, Input, message, Row, Upload} from 'antd';
import * as styles from './form.module.scss';
import {GAP_SIZE_2} from '../../constants';
import uploadImage from '../../images/icon-upload.svg';
import {useState} from 'react';
import {useForm} from 'antd/lib/form/Form';
import {directus} from '../../utils/directus';
import {UploadChangeParam, UploadFile} from 'antd/lib/upload/interface';

type FormValues = {
  name: string;
  contact: string;
  job: string;
  file: UploadChangeParam<UploadFile>;
};

export default function HireForm() {
  const [isLoading, setLoading] = useState(false);
  const form = useForm();
  const onSubmit = async (values: FormValues) => {
    const formData = new FormData();
    formData.append('title', 'resume-' + values.name);
    console.log(values);
    if (values.file.file.originFileObj) {
      formData.append('file', values.file.file.originFileObj);
    }
    try {
      setLoading(true);
      const resumeFile = await directus.files.createOne(formData);
      await directus.items('resume').createOne({
        name: values.name,
        contact: values.contact,
        job: values.job,
        file: {
          id: resumeFile.id,
        },
      });
      message.success('简历提交成功');
      form[0].resetFields();
    } catch (e) {
      console.error(e);
      message.error('简历提交失败');
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.title}>请填写应聘申请</div>
      <Form
        form={form[0]}
        onFinish={onSubmit}
        initialValues={{
          name: '',
          contact: '',
          job: '',
          file: null,
        }}
      >
        <Row gutter={[GAP_SIZE_2, 0]}>
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: '请输入您的姓名',
                },
              ]}
            >
              <Input placeholder="您的姓名" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="job"
              rules={[
                {
                  required: true,
                  message: '请输入应聘岗位',
                },
              ]}
            >
              <Input placeholder="应聘岗位" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contact"
              rules={[
                {
                  required: true,
                  message: '请输入手机号码',
                },
              ]}
            >
              <Input placeholder="您的手机号码" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="file"
              rules={[
                {
                  required: true,
                  message: '请上传简历',
                },
              ]}
            >
              <Upload
                className={styles.upload}
                showUploadList={false}
                multiple={false}
              >
                <button type="button">
                  <span>简历上传</span> <img src={uploadImage} alt="" />
                </button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                提交申请
              </Button>
            </Form.Item>
          </Col>
          <Col span={24} className={styles.tips}>
            我们将尊重您的隐私权，并承诺会对您的个人信息和其他数据进行严格保密。
          </Col>
        </Row>
      </Form>
      <div className={styles.contact}>
        <div>招聘联系人：黄先生</div>
        <div>联系电话：18024517905</div>
      </div>
    </div>
  );
}
