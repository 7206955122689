import React, {ReactNode, useState} from 'react';
import * as styles from './index.module.scss';
import {motion, AnimatePresence} from 'framer-motion';
import closeImage from '../../images/icon-close-circle.svg';
import clsx from 'clsx';

export type ViewModel = {
  columns: {
    title: string;
    dataIndex: string;
    key: string;
  }[];
  dataSource: any[];
  expand: (record: any) => ReactNode;
};
type PropTypes = {
  vm: ViewModel;
};

export default function HfAccordion({vm}: PropTypes) {
  const [activeIndex, setActiveIndex] = useState<null | number>(null);
  return (
    <div className={styles.accordion}>
      <div className={styles.thead}>
        {vm.columns.map(colum => (
          <div className={styles.title}>{colum.title}</div>
        ))}
        <div />
      </div>
      {vm.dataSource.map((item, index) => {
        const isActive = activeIndex === index;
        return (
          <>
            <div className={clsx(styles.row, isActive && styles.active)}>
              {vm.columns.map(column => (
                <div className={styles.column}>{item[column.dataIndex]}</div>
              ))}
              <div className={styles.actions}>
                <motion.div
                  className={styles.toggle}
                  whileHover={{scale: 1.1}}
                  whileTap={{scale: 0.9}}
                  animate={{rotate: isActive ? 0 : -45}}
                  onClick={() => setActiveIndex(isActive ? null : index)}
                >
                  <img src={closeImage} alt="X" />
                </motion.div>
              </div>
            </div>
            <AnimatePresence>
              {isActive && (
                <motion.div
                  className={styles.expandPanel}
                  initial={{height: 0, y: -20, opacity: 0}}
                  animate={{height: 200, y: 0, opacity: 1}}
                  exit={{height: 0, y: -20, opacity: 0}}
                >
                  <div className={styles.content}>{vm.expand(item)}</div>
                </motion.div>
              )}
            </AnimatePresence>
          </>
        );
      })}
    </div>
  );
}
